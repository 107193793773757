import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { navigate } from 'gatsby';
import SEO from '../components/seo';
import CssBaseline from '../components/CssBaseline';
import Section from '../components/Section';
import { Heading1, Paragraph } from '../components/Typography';
import Flexbox from '../components/Flexbox';
import Button from '../components/Button';
import Media from '../components/Media';
import defaultTheme from '../utils/themes';
import leftArrow from '../images/left-arrow.svg';

const Container = styled.div`
    align-items: center;
`;

const NotFoundPage = ({ theme }) => (
    <ThemeProvider theme={theme}>
        <>
            <SEO title='404: Not found' />

            <CssBaseline />

            <Section background='diamond' height='100vh' theme={theme}>
                <Flexbox
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    height='100vh'
                >
                    <Container>
                        <Heading1
                            color='white'
                            fontSize='4.7rem'
                            lineHeight='1.6'
                            marginBottom='0.2rem'
                            theme={theme}
                        >
                            404{' '}
                            <span style={{ fontSize: '2.8rem' }}>Error</span>
                        </Heading1>

                        <Paragraph
                            color='white'
                            fontSize='1.1rem'
                            marginBottom='2rem'
                        >
                            <strong>
                                The page you are looking for doesn’t exist.
                            </strong>
                        </Paragraph>

                        <Button
                            backgroundColor='secondary'
                            height='40px'
                            icon={
                                <Media
                                    src={leftArrow}
                                    marginTop='0px'
                                    marginBottom='0px'
                                    marginRight='0px'
                                    marginLeft='0px'
                                />
                            }
                            onClick={useCallback(() => navigate('/'), [])}
                        >
                            Back to home
                        </Button>
                    </Container>
                </Flexbox>
            </Section>
        </>
    </ThemeProvider>
);

NotFoundPage.propTypes = {
    theme: PropTypes.object
};

NotFoundPage.defaultProps = {
    theme: defaultTheme
};

export default NotFoundPage;
